import { useHistory } from 'react-router';
import { Box } from '@mui/material';

import useConfig from 'Lib/hooks/api/useConfig';

import './CustomerHeader.css';

export default function Header({ app, appBar }) {
    const history = useHistory();
    const { root_domain } = useConfig();

    const src = `https://cdn${root_domain}/${app.account.directory}/branding/${app.brandSettings.fileName}`;

    return (
        <Box display="flex" maxHeight="100%" onClick={() => history.push('/')}>
            <Box
                sx={{
                    bgcolor: 'common.background',
                    cursor: 'pointer',
                    my: appBar ? 0 : 5,
                    ml: appBar ? 0 : 4,
                    width: appBar ? '160px' : '260px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <img src={src} className="app-header-image" />
            </Box>
        </Box>
    );
}
